//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-760:_2184,_5328,_268,_7944,_3224,_5600,_1744,_5176;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-760')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-760', "_2184,_5328,_268,_7944,_3224,_5600,_1744,_5176");
        }
      }catch (ex) {
        console.error(ex);
      }